body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.brand-name{
  font-size: 30px;
  font-family: 'Bebas Neue', cursive;
  text-decoration: none;

}

.brand-desc{
  font-family: 'Martian Mono', monospace;
  color:white;
  font-size:20px;
}

#nav1{
  background-color:black; /*whichever you want*/
    opacity: 0.7; 
    filter:(opacity=50);  
}

.white-sucker{
  color:white;
}

@media screen and (max-width: 800px) {
  .left, .main, .right {
    width: 100%; /* The width is 100%, when the viewport is 800px or smaller */
  }
}